<div class="main-container centered">
  <content>
    <div class="welcome">
      <div class="title">Benvenuto!</div>
      <div class="subtitle">Ti farò una serie di domande per conoscerci meglio e per elaborare il tuo giusto piano di allenamento e nutrizione in base ai dati che ci fornirai.</div>
    </div>
    <div class="login-register">
      <a href="/onboarding/step-1" class="button-default-no-icon">iniziamo</a>
    </div>
  </content>
</div>
