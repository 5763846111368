<div class="main-container centered">

  <content>
      <div class="headline">accedi al tuo account</div>
      <div class="social-login-buttons">
          <a href="services/users/loginUser.php?provider=Google" class="button-default-no-icon">continua con google</a>
          <a href="services/users/loginUser.php?provider=Facebook" class="button-default-no-icon">continua con facebook</a>
          <!--<a href="#" class="button-default-no-icon">continua con apple</a>-->
      </div>
      <div class="oppure">oppure</div>

      <form name="login" id="userLogin" action="" method="post" enctype="multipart/form-data">
          <div class="user-info">
              <!-- text imput without helper text-->
                  <div class="text-input">
                      <div class="title">Indirizzo email</div>
                      <div class="input input-control">
                          <input type="text" name="email" id="userEmail" placeholder="something@domain.com" />
                          <div class="error"></div>
                      </div>
                      <div class="helper-text"></div>
                  </div>
                  <!-- / text imput without helper text-->
                  <!-- text imput without helper text-->
                  <div class="text-input">
                      <div class="title">Password</div>
                      <div class="input input-control">
                          <input type="password" name="password" id="userPassword" placeholder="MyP@assword" />
                          <div class="error"></div>
                      </div>
                      <div class="helper-text"></div>
                  </div>
                  <!-- / text imput without helper text-->
                  <div class="input-control show-password" style="margin-bottom: 1em;">
                      <input type="checkbox" class="round-checkbox" (click)="showPassword('userPassword')"/>Show password
                  </div>
          </div>
          <div class="login-register">
              <button (click)="onLoginSubmit($event)" type="submit" id="submitLogin" class="button-default-no-icon">login</button>
              <a routerLink="forgot-password" class="button-default-no-icon outline">password dimenticata?</a>
          </div>
      </form>
      <div></div>
  </content>
</div>
