<div class="main-container centered">
  <content>
    <form id="registrazione" name="registrazione" action="" method="post" enctype="multipart/form-data">
      <div class="headline">Registra un nuovo account</div>

      <div class="social-login-buttons">
          <a href="#" class="button-default-no-icon">continua con google</a>
          <a href="#" class="button-default-no-icon">continua con facebook</a>
          <!--<a href="#" class="button-default-no-icon">continua con apple</a>-->
      </div>
      <div class="oppure">oppure</div>
      <div class="user-info">
          <!-- text imput without helper text-->
          <div class="text-input">
              <div class="title">Indirizzo email</div>
              <div class="input input-control">
                  <input type="text" name="email" id="userEmail" placeholder="something@domain.com">
                  <div class="error"></div>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
          <!-- text imput without helper text-->
          <div class="text-input">
              <div class="title">Password</div>
              <div class="input input-control">
                  <input type="password" name="password" id="userPassword" placeholder="MyP@assword">
                  <div class="error"></div>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
          <div class="input-control show-password" style="margin-bottom: 1em;">
              <input type="checkbox" class="round-checkbox" (click)="showPassword('userPassword')">
          </div>
          <!-- text imput without helper text-->
          <div class="text-input">
              <div class="title">Numero di telefono</div>
              <div class="input input-control">
                  <input type="number" name="phone" id="userPhone" placeholder="+39 02 1234567">
                  <div class="error"></div>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
      </div>
      <div class="login-register">
          <a (click)="onSubmit($event)" id="registerBtn" class="button-default-no-icon">crea un nuovo account</a>
          <a routerLink="login" class="button-default-no-icon outline">hai già un account? login</a>
      </div>
      <div></div>
    </form>
  </content>
</div>
