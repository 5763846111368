import { Injectable } from '@angular/core';

//import ricetteConsigliateJson from '../../assets/json/ricette-consigliate.json';
//import alimentiJson from '../../assets/json/alimenti.json';

import pianoAlimentareJson from '../../assets/json/piano-alimentare.json';
import ricetteUserJson from '../../assets/json/ricette-user.json';
import pastiCheckedPerGiornoJson from '../../assets/json/pasti-checked-per-giorno.json';
import listeRicetteSelezionatePerGiornoJson from '../../assets/json/liste-ricette-selezionate-per-giorno.json';

import { NutrizioneDataService } from '../_services/nutrizione.data.service'
import { debounce } from 'rxjs/operators';


function formattedDate(d) {
  const yyyy = d.getFullYear();
  let mm = d.getMonth() + 1; // Months start at 0!
  let dd = d.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  var formatted = yyyy + '-' + mm + '-' + dd;
  return formatted;
}

/*
  PARAMETERS:
  listaAlimenti: array of aliments [{idalimento,c,p,g,....}, ..]
  idalimento:number

  RETURN:
  the selected aliment or false if it doesn't exist
*/
function getAlimentoById(listaAlimenti, idalimento) {
  for (var i = 0; i < listaAlimenti.length; i++) {
    if (parseInt(idalimento) == parseInt(listaAlimenti[i].idalimento)) {
      return listaAlimenti[i];
    }
  }
  return false;
}

/*
  PARAMETERS:
  listaAlimenti: array of aliments []{idalimento,c,p,g,....}, ..]
  totaliPasto: {c:number, p:number, g:number}
  ricettaUser:
    {
      f: QuantitaAlimento,
      c: QuantitaAlimento[], //array of QuantitaAlimento
      p: QuantitaAlimento[], //array of QuantitaAlimento
      g: QuantitaAlimento[], //array of QuantitaAlimento
    }

  QuantitaAlimento = {idalimento:number, quantita:number}

  example of ricettaUser:
  {
    f: {idalimento: 16,quantita: 100}, //verdure a foglia
    c: [
      {idalimento: 2, percentuale: 0.3}, //avena
      {idalimento: 9, percentuale: 0.7}, //cereali cotti
    ],
    p: [
      {idalimento: 20, percentuale: 1} //pesce magro
    ],
    g: [
      {idalimento: 47, percentuale: 1} //noci
    ]
  }

  RETURN:
  {
    alimenti: QuantitaAlimento[], //array of QuantitaAlimento
    totali: {c:number, p:number, g:number, calorie:number}
  }

*/
function getRicettaUserQuantitaPerPasto(listaAlimenti, totaliPasto, ricettaUser) {

  var out = {
    alimenti: [], //{idalimento,quantita}
    totali: { calorie: 0, c: 0, p: 0, g: 0 }
  };
  //console.log(totaliPasto);
  var totaliIter = JSON.parse(JSON.stringify(totaliPasto));

  if (ricettaUser.f) {
    var alimento = getAlimentoById(listaAlimenti, ricettaUser.f.idalimento);
    totaliIter.c -= alimento.c * ricettaUser.f.quantita;
    totaliIter.p -= alimento.p * ricettaUser.f.quantita;
    totaliIter.g -= alimento.g * ricettaUser.f.quantita;
    out.alimenti.push(ricettaUser.f);
  }

  var fonti = ['c', 'p', 'g'];
  for (var k = 0; k < fonti.length; k++) {
    var nomeFonte = fonti[k];
    if (ricettaUser[nomeFonte]) {
      var currFonteTotale = totaliIter[nomeFonte];

      for (var i = 0; i < ricettaUser[nomeFonte].length; i++) {
        var alimento = getAlimentoById(listaAlimenti, ricettaUser[nomeFonte][i].idalimento);
        var grammiFonteTotDelCibo = currFonteTotale * ricettaUser[nomeFonte][i].percentuale;

        var grammi = Math.trunc(grammiFonteTotDelCibo / alimento[nomeFonte]);
        //check min/max di grammi
        if (grammi < parseFloat(alimento["minQ-gr"])) {
          grammi = parseFloat(alimento["minQ-gr"]);
        } else if (grammi > parseFloat(alimento["maxQ-gr"])) {
          grammi = parseFloat(alimento["maxQ-gr"]);
        }

        for (var j = 0; j < fonti.length; j++) {
          var nomeFonteJ = fonti[j];
          totaliIter[nomeFonteJ] -= alimento[nomeFonteJ] * grammi;
        }

        out.alimenti.push({
          idalimento: ricettaUser[nomeFonte][i].idalimento,
          quantita: grammi
        });
      }
    }
  }

  for (var i = 0; i < fonti.length; i++) {
    var nomeFonte = fonti[i];
    out.totali[nomeFonte] = Math.round(totaliPasto[nomeFonte] - totaliIter[nomeFonte]);
  }
  out.totali.calorie = out.totali.c * 4 + out.totali.p * 4 + out.totali.g * 9;
  return out;
}

@Injectable({
  providedIn: 'root'
})
export class NutrizioneService {
  ricetteConsigliate;
  alimenti;

  pianiAlimentari;
  ricetteUser;
  pastiCheckedPerGiorno;
  listeRicetteSelezionatePerGiorno;

  constructor(private nutrizioneDataService: NutrizioneDataService) {

    this.pianiAlimentari = [pianoAlimentareJson]; //caricamento ajax
    //this.ricetteConsigliate = ricetteConsigliateJson;
    this.alimenti = {};

    // get the Result from database

    this.ricetteUser = ricetteUserJson;
    this.pastiCheckedPerGiorno = pastiCheckedPerGiornoJson;
    this.listeRicetteSelezionatePerGiorno = listeRicetteSelezionatePerGiornoJson;


    var totaliPasto = {
      c: 154, p: 64, g: 32
    };
    var ricettaUser = {
      f: { idalimento: 16, quantita: 100 }, //verdure a foglia
      c: [
        { idalimento: 2, percentuale: 0.3 }, //avena
        { idalimento: 9, percentuale: 0.7 }, //cereali cotti
      ],
      p: [
        { idalimento: 20, percentuale: 1 } //pesce magro
      ],
      g: [
        { idalimento: 47, percentuale: 1 } //noci
      ]
    };
    var r = getRicettaUserQuantitaPerPasto(this.alimenti, totaliPasto, ricettaUser);
    //console.log('totali pasto',totaliPasto);
    //console.log('ricetta user',ricettaUser);
    //console.log('ret',r);

    this.nutrizioneDataService.getMacroFoodList()
      .subscribe({
        next: (result) => {
          this.alimenti = result;


        },
        error: error => {
          return this.ricetteConsigliate;
          console.log(error);
        }
      });

  }


  getAlimenti() {

    return this.alimenti;
  }

  getAlimentoById(idalimento) {
    for (var i = 0; i < this.alimenti.length; i++) {
      if (parseInt(idalimento) == parseInt(this.alimenti[i].idalimento)) {
        return this.alimenti[i];
      }
    }
    return false;
  }

  getRicetteConsigliate() {

    //// get the Result from database
    //this.nutrizioneDataService.getMealPlan('2024-03-17')
    //  .subscribe({
    //    next: (result) => {
    //      if (result.Suggerite) {
    //        this.ricetteConsigliate = result.Suggerite;


    //      }

    //    },
    //    error: error => {
    //      return this.ricetteConsigliate;
    //      console.log(error);
    //    }
    //  });
    return this.ricetteConsigliate;

  }

  getRicetteUser() {
    return this.ricetteUser;
  }

  private renderRicettaUser(pianoDelPasto, idricetta) {

    var ricetta = this.getRicettaUserById(idricetta);

    ricetta.render = this.getRicettaUserQuantitaPerPasto(pianoDelPasto, idricetta);
    if (ricetta.render) {
      //compilo il testo della ricetta includendo gli ingredienti e quantità
      var ings = [];
      for (var k = 0; k < ricetta.render.alimenti.length; k++) {
        var alimentoK = this.getAlimentoById(ricetta.render.alimenti[k].idalimento);
        ings.push(ricetta.render.alimenti[k].quantita + "g " + alimentoK.nome);
      }
      ricetta.render.testo = ricetta.nome + ": " + ings.join(', ');
    }
    return ricetta;
  }

  getRenderedRicetteUser(pianoDelPasto) {
    var ricette = [];
    for (var i = 0; i < this.ricetteUser.length; i++) {
      ricette.push(JSON.parse(JSON.stringify(this.renderRicettaUser(pianoDelPasto, this.ricetteUser[i].idricetta))));
    }
    return ricette;
  }

  salvaRicetteUser(ricetteUser) {
    this.ricetteUser = ricetteUser;
    //ajax
    return;
  }

  salvaNuoveRicetteUser(nuoveRicetteUser) {
    this.ricetteUser = this.ricetteUser.concat(nuoveRicetteUser);
    //console.log('concat',this.ricetteUser);

    return;
  }

  getPastiChecked(giornoX) {


    console.log('STATIC DATA-getPastiChecked');
    if (this.pastiCheckedPerGiorno[giornoX]) {
      return this.pastiCheckedPerGiorno[giornoX];
    }
    return [];
  }

  salvaPastiChecked(giornoX, pastiChecked) {
    alert('COMMENTS BY VIPS')
    //this.pastiCheckedPerGiorno[giornoX] = pastiChecked;
    //ajax
  }

  public getRicettaConsigliataById(id) {
    for (var i = 0; i < this.ricetteConsigliate.length; i++) {
      if (this.ricetteConsigliate[i].idricetta == id) {
        return this.ricetteConsigliate[i];
      }
    }
    return false;
  }

  public getRicettaUserById(id) {
    for (var i = 0; i < this.ricetteUser.length; i++) {
      if (this.ricetteUser[i].idricetta == id) {
        return this.ricetteUser[i];
      }
    }
    return false;
  }

  /*
  private getRicettaUserFontiTotali(ricettaUser) {

    var fontiTotali = {
      calorie: 0,
      c: 0,
      p: 0,
      g: 0
    };

    for(var i=0; i<ricettaUser.ingredienti.length; i++) {
      var idalimento = ricettaUser.ingredienti[i].idalimento;
      var quantita = ricettaUser.ingredienti[i].quantita;

      var alimento;
      for(var j=0; j<this.alimenti.length; j++)
        if(this.alimenti[j].idalimento == idalimento)
          alimento = this.alimenti[j];

      if(alimento) {
        fontiTotali.c += quantita*alimento.c;
        fontiTotali.p += quantita*alimento.p;
        fontiTotali.g += quantita*alimento.g;
      }
    }
    fontiTotali.calorie = fontiTotali.c*4 + fontiTotali.p*4 + fontiTotali.g*9;
    return fontiTotali;
  }

  public getRicettaUserFontiTotaliById(idricetta) {
    var ricetta = this.getRicettaUserById(idricetta);
    var fontiTotali = this.getRicettaUserFontiTotali(ricetta);
    return fontiTotali;
  }*/

  public getRicettaUserQuantitaPerPasto(totaliPasto, idricetta): any {
    var ricettaUser = this.getRicettaUserById(idricetta);
    if (totaliPasto && ricettaUser && this.alimenti) {
      //console.log(this.alimenti,totaliPasto,ricettaUser);
      var d = getRicettaUserQuantitaPerPasto(this.alimenti, totaliPasto, ricettaUser.ingredienti);
      return d;
    }
    return false;
  }

  /*
   * INPUT:
   * pianoDelPasto = {calorie:number, c:number, p:number, g:number }
   * giornoX:string yyyy/mm/dd
   *
   * Tutte le ricette selezionate, suddivise per pasto. (consigliate + user)
   * {descrizione, calorie, g, p, c}
   *
   * Esempio output:
   * {
       colazione: {
         testo: 'Cereali muesli + 1 kiwi',
         calorie: 110, g: 10, p: 30, c: 60
       },
       pranzo: {
         testo: 'Riso integrale con zucchine e gamberetti, Yogurt bianco',
         calorie: 180, g: 10, p: 30, c: 60
       },
       cena: {
         testo: 'Petto di pollo alla griglia con broccoli, Insalata verde',
         calorie: 140, g: 10, p: 30, c: 60
       },
       spuntino: {
         testo: 'Noci, Una mela',
         calorie: 130, g: 10, p: 30, c: 60
       },
     }
   *
   *
   */
  getPastiComposizione(giornoX) {
    console.log('STATIC DATA');

    var ricetteSel = this.listeRicetteSelezionatePerGiorno[giornoX];

    var pianoAlimentare = this.getPianoDelGiorno(giornoX);
    var pasti = {};

    for (var pasto in ricetteSel) {
      var pianoDelPasto = pianoAlimentare.piano[pasto];
      //console.log(pasto,pianoAlimentare,pianoDelPasto);
      var calorie = 0;
      var testo = [];
      var c = 0;
      var p = 0;
      var g = 0;

      var ricetteConsigliateSel = ricetteSel[pasto].consigliate;
      if (ricetteConsigliateSel) {
        for (var i = 0; i < ricetteConsigliateSel.length; i++) {
          var idRicetta = ricetteConsigliateSel[i];
          var ricetta = this.getRicettaConsigliataById(idRicetta);
          calorie += parseInt(ricetta.calorie);
          c += parseInt(ricetta.c);
          p += parseInt(ricetta.p);
          g += parseInt(ricetta.g);
          testo.push(ricetta.Descrizione);
        }
      }

      var ricetteUserSel = ricetteSel[pasto].user;

      if (ricetteUserSel) {
        for (var i = 0; i < ricetteUserSel.length; i++) {
          var idricetta = ricetteUserSel[i];

          var ricetta = this.getRicettaUserById(idricetta);
          var r = this.getRicettaUserQuantitaPerPasto(pianoDelPasto, idricetta);
          //console.log(r);
          if (r) {
            calorie += r.totali.calorie;
            c += r.totali.c;
            p += r.totali.p;
            g += r.totali.g;

            //compilo il testo della ricetta includendo gli ingredienti e quantità
            var ings = [];
            console.log(r);
            for (var k = 0; k < r.alimenti.length; k++) {
              console.log(r.alimenti[k]);
              var alimentoK = this.getAlimentoById(r.alimenti[k].idalimento);
              ings.push(alimentoK.nome + r.alimenti[k].quantita + "g");
            }
            testo.push(ricetta.nome + ": " + ings.join(', '));
          }
        }
      }

      pasti[pasto] = {
        calorie: calorie,
        c: c,
        p: p,
        g: g,
        testo: testo.join('; ')
      };
    }
    return pasti;
  }

  getListeRicetteSelezionate(giornoX) {
    if (this.listeRicetteSelezionatePerGiorno[giornoX]) {
      return this.listeRicetteSelezionatePerGiorno[giornoX];
    }
    var l = {
      "colazione": { "consigliate": [], "user": [] },
      "pranzo": { "consigliate": [], "user": [] },
      "cena": { "consigliate": [], "user": [] },
      "spuntino": { "consigliate": [], "user": [] }
    };
    return l;
  }

  salvaListeRicetteSelezionate(giornoX, listaRicetteSelezionate) {
    this.listeRicetteSelezionatePerGiorno[giornoX] = listaRicetteSelezionate;
    //ajax
  }

  getPianiAlimentari() {
    return this.pianiAlimentari;
  }

  getPianoAlimentare() {
    return this.pianiAlimentari[0];
  }

  salvaPianoAlimentare(pianoAlimentare) {
    this.pianiAlimentari[0] = pianoAlimentare;
  }

  /*
    Dato il giorno ottiene il piano stipulato dal coach.
    Se richiesto, la funzione applica la progressione.

    input: giornoX nel formato yyyy-mm-dd

    output: {
      piano: {},        // Il piano del coach, esempio in /assets/json/pianoAlimentare.json
      fontiTotali: {},  // per ogni fonte (k,c,p,g,f), calcola il totale del giorno
      tipoGiorno: ""    // "giornoOn","giornoOff","giornoSgarro"
    }
  */
  getPianoDelGiorno(giornoX): any {

    var pa = this.pianiAlimentari[0];

    var out = {
      piano: {},
      fontiTotali: {},
      tipoGiorno: ""
    };

    if (!(giornoX >= pa.dataInizio && giornoX < pa.dataFine)) {
      return false; //nessun piano per questo giorno
    }

    //numero della settimana da 0 a 6, con 0 che vale per lunedi
    var giornoXSettimanaNum = (new Date(giornoX).getDay() + 6) % 7;

    if (pa.giornoOn.giorniSettimana.indexOf(giornoXSettimanaNum) != -1) {
      out.tipoGiorno = 'giornoOn';
    } else if (pa.giornoOff.giorniSettimana.indexOf(giornoXSettimanaNum) != -1) {
      out.tipoGiorno = 'giornoOff';
    } else {
      out.tipoGiorno = "giornoSgarro";
      return out;
    }

    out.piano = pa[out.tipoGiorno].piano;

    //compensazione lineare piano progressivo

    if (pa.pianoProgressivo.attivo && pa.pianoProgressivo.giorno == out.tipoGiorno) {
      var giornoXTimestamp = new Date(giornoX).getTime();

      var dataFineProgressioneTimestamp =
        new Date(pa.dataInizio).getTime() + pa.pianoProgressivo.settimane * 60 * 60 * 24 * 7 * 1000;

      //var dataFineProgressione = formattedDate(new Date(dataFineProgressioneTimestamp));
      //console.log('fine prog',dataFineProgressione);

      var grammiOffs;
      if (dataFineProgressioneTimestamp < giornoXTimestamp) {
        grammiOffs = pa.pianoProgressivo.grammi;
      } else {
        /*
          Data la proporzione:
          (dataFineProgressione - pa.dataInizio) : (giornoX - pa.dataInizio) = pa.pianoProgressivo.grammi : grammiOffs;
          allora:
          (giornoX - pa.dataInizio) * pa.pianoProgressivo.grammi / (dataFineProgressione - pa.dataInizio);
        */

        var dataInizioTimestamp = new Date(pa.dataInizio).getTime();
        /*console.log(
          pa.dataInizio, giornoX, dataFineProgressione,
          giornoXTimestamp,
          dataInizioTimestamp,
          (giornoXTimestamp - dataInizioTimestamp),
          pa.pianoProgressivo.grammi,
          dataFineProgressioneTimestamp,
          dataInizioTimestamp,
          (dataFineProgressioneTimestamp - dataInizioTimestamp)
        )*/
        grammiOffs =
          (giornoXTimestamp - dataInizioTimestamp) * pa.pianoProgressivo.grammi / (dataFineProgressioneTimestamp - dataInizioTimestamp);
      }
      //console.log("grammiOffs",grammiOffs);
      /*
      for (let pasto in out.piano) {
        out.piano[pasto][pa.pianoProgressivo.fonte] += grammiOffs;
        out.piano[pasto][pa.pianoProgressivo.fonte] = parseInt(out.piano[pasto][pa.pianoProgressivo.fonte]);
      }*/
      out.piano[pa.pianoProgressivo.pasto][pa.pianoProgressivo.fonte] = parseInt(grammiOffs);
      out.piano[pa.pianoProgressivo.pasto].calorie = out.piano[pa.pianoProgressivo.pasto].c * 4 + out.piano[pa.pianoProgressivo.pasto].p * 4 + out.piano[pa.pianoProgressivo.pasto].g * 9;
      out.fontiTotali[pa.pianoProgressivo.fonte] = parseInt(grammiOffs);
    }

    //calcolo i totali
    for (let pasto in out.piano) {
      //console.log(pasto);

      for (let fonte in out.piano[pasto]) {
        //console.log(fonte);
        out.fontiTotali[fonte] = out.fontiTotali[fonte] ? out.fontiTotali[fonte] : 0;
        out.fontiTotali[fonte] += out.piano[pasto][fonte];
        //console.log(out.piano[pa.pianoProgressivo.pasto],out.fontiTotali);
      }
    }

    return out;
  }
}
