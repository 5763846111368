import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounce, map } from 'rxjs/operators';
import { JwtPayload, jwtDecode } from "jwt-decode";
import { LoginReponse, environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private userSubject: BehaviorSubject<any | null>;
  public user: Observable<any | null>;
  @Input() _ApiReponse!: LoginReponse;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.user = this.userSubject.asObservable();
    this._ApiReponse = {};
  }

  public get userValue() {
    return this.userSubject.value;
  }

  login(email: string, password: string) {

    return this.http.post<any>(`${environment.apiUrl}/services/loginUser.php`, { email: email, password: password, provider: "Standard" })
      .pipe(map(user => {
        debugger;

        if (user.status) {
          this._ApiReponse.response = false;
          this._ApiReponse.message = user.status.error.msg;
        }
        else {
            
          

          let decoded: any = jwtDecode<JwtPayload>(user.data.token);

          let _ruolo = decoded.data.ruolo;
          let _userid = decoded.data.userid;

          //let _user = {};
          localStorage.setItem('userRuolo', _ruolo);
          localStorage.setItem('userUserid', _userid);
          localStorage.setItem('userToken', user.data.token);
          localStorage.setItem('user', JSON.stringify(user));

          this._ApiReponse.response = true;
          this._ApiReponse.message = "";
          this._ApiReponse.ruolo = _ruolo;
          this._ApiReponse.userid = _userid;
        }

        this.userSubject.next(user);
        return this._ApiReponse
      }))
      ;
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem('user');
    this.userSubject.next(null);
    this.router.navigate(['/login']);
  }
  /*
  register(user: User) {
     return this.http.post(`${environment.apiUrl}/users/register`, user);
  }

  getAll() {
     return this.http.get<User[]>(`${environment.apiUrl}/users`);
  }

  getById(id: string) {
     return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
  }

  update(id: string, params: any) {
     return this.http.put(`${environment.apiUrl}/users/${id}`, params)
         .pipe(map(x => {
             // update stored user if the logged in user updated their own record
             if (id == this.userValue?.id) {
                 // update local storage
                 const user = { ...this.userValue, ...params };
                 localStorage.setItem('user', JSON.stringify(user));

                 // publish updated user to subscribers
                 this.userSubject.next(user);
             }
             return x;
         }));
  }

  delete(id: string) {
     return this.http.delete(`${environment.apiUrl}/users/${id}`)
         .pipe(map(x => {
             // auto logout if the logged in user deleted their own record
             if (id == this.userValue?.id) {
                 this.logout();
             }
             return x;
         }));
  }*/
}
