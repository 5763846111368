import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-registrati',
  templateUrl: './registrati.component.html',
  styleUrls: ['./registrati.component.css']
})
export class RegistratiComponent implements OnInit {

  constructor() { }

  form;
  email;
  password;
  telfNumber;
  howManyErrors = 0;

  ngOnInit(): void {
    // Assumes only one form in the page
    this.form = document.querySelector('form');

    // Selecting all fields to check
    // (Dont forget to treat them in validateInputs)
    this.email = document.getElementById('userEmail') as HTMLInputElement;
    this.password = document.getElementById('userPassword') as HTMLInputElement;
    this.telfNumber = document.getElementById('userPhone') as HTMLInputElement;
  }

  // Function that toggles show/hide password transforming
  // input type="password" to type="text" and viceversa
  showPassword(fieldID) {
    // For calling it like:
    // onclick="passwordValidation('userPassword')"
    // Styled with .show-password
    let thisField = document.getElementById(fieldID) as HTMLInputElement;
    if (thisField.type === "password") {
        thisField.type = "text";
    } else {
        thisField.type = "password";
    }
  }

  // ====================================================================
  // V V V V V FORM VALIDATION V V V V V
  // ====================================================================
  //
  // ASSUMES ONE FORM PER PAGE
  //
  // This validation script is as simple as it can get. Fields to validate
  // are manually defined ousite of HTML native API, in this very file.
  //
  // Styling is done under the form-validation section of components.css
  //
  // Requires putting a <div class="error"></div> just under the input filed.
  //
  // Requires adding .input-control class to the input wrapper.


  // Setting error message, styling and adding +1 to howManueErrors
  setError (element, message) {
      const inputControl = element.parentElement;
      const errorDisplay = inputControl.querySelector('.error');

      errorDisplay.innerText = message;
      inputControl.classList.add('error');
      inputControl.classList.remove('success')

      this.howManyErrors++;
  }

  // Setting success message, styling
  setSuccess(element){
      const inputControl = element.parentElement;
      const errorDisplay = inputControl.querySelector('.error');

      errorDisplay.innerText = '';
      inputControl.classList.add('success');
      inputControl.classList.remove('error');
  };

  // Validating email
  isValidEmail (email){
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  // Block of code that validates all the fields we've got in line +34
  validateInputs (){
      const emailValue = this.email.value.trim();
      const passwordValue = this.password.value.trim();
      const telfNumberValue = this.telfNumber.value.trim();

      if(emailValue === '') {
          this.setError(this.email, 'Inserire un indirizzo e-mail valido');
      } else if (!this.isValidEmail(emailValue)) {
          this.setError(this.email, 'Inserire un indirizzo e-mail valido');
      } else {
          this.setSuccess(this.email);
      }

      if(passwordValue === '') {
          this.setError(this.password, 'È necessaria una password');
      } else if (passwordValue.length < 8 ) {
          this.setError(this.password, 'La password deve essere di almeno 8 caratteri')
      } else {
          this.setSuccess(this.password);
      }

      if(telfNumberValue === '') {
          this.setError(this.telfNumber, 'Inserire un numero di telefono');
      } else {
          this.setSuccess(this.telfNumber);
      }

  };

  onSubmit(e) {

    e.preventDefault();
    this.howManyErrors = 0;
    this.validateInputs();
    if (this.howManyErrors !== 0) {
        return
    }

    const emailValue = this.email.value.trim();
    const passwordValue = this.password.value.trim();
    const telfNumberValue = this.telfNumber.value.trim();

    console.log(emailValue,passwordValue,telfNumberValue);
  }

}
