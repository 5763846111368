<div class="wrapper" *ngIf="pianoAllenamento">
  <div class="allenamento-group">
      <div class="up">
          <div class="title">programma di allenamento</div>
          <div class="description">
              <div class="title">titolo</div>
              <div class="time-group">
                <span>
                  {{weeksBetween(pianoAllenamento.dataInizio, pianoAllenamento.dataFine)}} settimane
                </span>

                <input type="date" name="from-week" id="from-week" [(ngModel)]="pianoAllenamento.dataInizio" format="yyyy-mm-dd">
                -
                <input type="date" name="to-week" id="to-week" [(ngModel)]="pianoAllenamento.dataFine" format="yyyy-mm-dd">
              </div>
          </div>
      </div>
      <div class="bottom">
          <!-- week -->
          <div class="week"
            *ngFor="let settimana of pianoAllenamento.settimane; index as nSettimana"
          >
              <div class="title">
                  <!-- dropdown-menu -->
                  <details class="dropdown-menu" #dropdownSettimana>
                      <summary>settimana {{nSettimana+1}}</summary>
                      <ul>
                          <li (click)="nuovaSettimanaClick(); dropdownSettimana.removeAttribute('open')">nuova settimana</li>
                          <li (click)="duplicaSettimanaClick(settimana); dropdownSettimana.removeAttribute('open')">duplica</li>
                          <li (click)="eliminaSettimanaClick(nSettimana); dropdownSettimana.removeAttribute('open')">elimina</li>
                      </ul>
                  </details>
                  <!-- / dropdown-menu -->
              </div>
              <!-- session -->
              <div class="session"
                *ngFor="let giornoOn of settimana.giorniOn; index as nGiornoOn"
              >
                  <div class="title">
                      <!-- dropdown-menu -->
                      <details class="dropdown-menu" #dropdownGiornoOn>
                          <summary>Giorno-on {{nGiornoOn+1}}</summary>
                          <ul>
                              <li (click)="nuovoGiornoClick(nSettimana); dropdownGiornoOn.removeAttribute('open')">nuovo giorno-on</li>
                              <li (click)="duplicaGiornoClick(nSettimana,giornoOn); dropdownGiornoOn.removeAttribute('open')">duplica</li>
                              <li (click)="eliminaGiornoClick(nSettimana,nGiornoOn); dropdownGiornoOn.removeAttribute('open')">elimina</li>
                          </ul>
                      </details>
                      <!-- / dropdown-menu -->
                  </div>
                  <div class="description">
                      <img [src]="giornoOn.linkImmagine">
                      <div class="text-group">
                        <!-- dropdown-menu -->
                        <details class="dropdown-menu">
                            <summary>video riscaladamento</summary>
                            <ul>
                                <li>Video 1</li>
                                <li>Video 2</li>
                                <li>Video 3</li>
                            </ul>
                        </details>
                        <!-- / dropdown-menu -->
                        <input type="text" [(ngModel)]="giornoOn.titolo">
                        <textarea type="text" [(ngModel)]="giornoOn.descrizione">Descrizione della sessione</textarea>
                      </div>
                  </div>
                  <!-- activity -->
                  <div class="activity"
                    *ngFor="let circuito of giornoOn.circuiti; index as nCircuito"
                  >
                      <div class="top">
                          <div class="options">
                              <div class="title">
                                  <!-- dropdown-menu -->
                                  <details class="dropdown-menu" #dropdownCircuito>
                                      <summary>Circuito {{nCircuito+1}}</summary>
                                      <ul>
                                          <li (click)="nuovoCircuitoClick(nSettimana,nGiornoOn); dropdownCircuito.removeAttribute('open')">nuovo circuito</li>
                                          <li (click)="duplicaCircuitoClick(nSettimana,nGiornoOn,circuito); dropdownCircuito.removeAttribute('open')">duplica</li>
                                          <li (click)="eliminaCircuitoClick(nSettimana,nGiornoOn,nCircuito); dropdownCircuito.removeAttribute('open')">elimina</li>
                                      </ul>
                                  </details>
                                  <!-- / dropdown-menu -->
                              </div>
                              <div class="description">
                                <select [(ngModel)]="circuito.idcircuito">
                                  <option *ngFor="let c of listaCircuiti" [ngValue]="c.idcircuito">
                                    {{c.nome}}
                                  </option>
                                </select>
                              </div>
                          </div>
                          <div class="repetitions">
                              <div class="title">ripetizioni</div>
                              <input type="text" [(ngModel)]="circuito.ripetizioni">
                          </div>
                          <div class="rest">
                              <div class="title">riposo</div>
                              <input type="text" [(ngModel)]="circuito.riposo">
                          </div>
                      </div>
                      <div class="bottom">
                          <!-- exercises -->
                          <div class="exercises">
                            <div class="title">
                              <!-- dropdown-menu -->
                              <details class="dropdown-menu" #dropdownEsercizi>
                                  <summary>esercizi</summary>
                                  <ul>
                                      <li (click)="nuovoEsercizioClick(nSettimana,nGiornoOn,nCircuito); dropdownEsercizi.removeAttribute('open')">nuovo esercizio</li>
                                  </ul>
                              </details>
                              <!-- / dropdown-menu -->
                            </div>
                            <table>
                              <thead>
                                <th>Esercizio</th>
                                <th>tempo (s)</th>
                                <th>ripetizioni</th>
                                <th>carico (kg)</th>
                                <th>Progressione</th>
                                <th>Da</th>
                                <th>A</th>
                              </thead>
                              <tbody>
                                <!-- exercise -->
                                <tr
                                  class="exercise"
                                  *ngFor="let esercizio of circuito.esercizi; index as nEsercizio"
                                >
                                  <td class="esercizio-scelta">
                                    <select  [(ngModel)]="esercizio.idesercizio">
                                      <option *ngFor="let e of listaEsercizi" [ngValue]="e.idesercizio">
                                        {{e.nome}}
                                      </option>
                                    </select>
                                    <!-- dropdown-menu -->
                                    <!--
                                    <details class="dropdown-menu grey">
                                      <summary>{{esercizio.nome}}</summary>
                                      <ul>
                                        <li>Option 1</li>
                                        <li>Option 2</li>
                                        <li>Option 3</li>
                                      </ul>
                                    </details>
                                    -->
                                    <!-- / dropdown-menu -->
                                  </td>

                                  <td class="time">
                                    <input type="number" [(ngModel)]="esercizio.tempo">
                                  </td>

                                  <td class="repetitions">
                                    <input type="number" [(ngModel)]="esercizio.ripetizioni">
                                  </td>

                                  <td class="weight">
                                    <input type="number" [(ngModel)]="esercizio.carico">
                                  </td>

                                  <td class="progressione">
                                    <select  [(ngModel)]="esercizio.progressione">
                                      <option *ngFor="let p of tipiProgressione" [ngValue]="p">
                                        {{p}}
                                      </option>
                                    </select>
                                  </td>

                                  <td class="from">
                                    <input type="number" [(ngModel)]="esercizio.progressioneDa">
                                  </td>

                                  <td class="to">
                                    <input type="number" [(ngModel)]="esercizio.progressioneA">
                                  </td>

                                </tr>
                                <!-- / exercise -->
                              </tbody>
                            </table>

                          </div>
                          <!-- / exercises -->
                      </div>
                  </div>
                  <!-- / activity -->
              </div>
              <!-- / session -->
          </div>
          <!-- / week -->
      </div>
  </div>
  <div class="allenamento-bottom-options">
      <div class="restart" (click)="onRipristinaPianoAllenamentoClick()">
        Ripristina il piano di allenamento
      </div>
      <div class="templates">
          <div class="template-import" (click)="onImportaUnTemplateClick()">
            importa un template
          </div>
          <div class="template-list">
              <!-- dropdown-menu -->
              <details class="dropdown-menu">
                  <summary>Template list</summary>
                  <ul>
                      <li>Template 1</li>
                      <li>Template 2</li>
                      <li>Template 3</li>
                  </ul>
              </details>
              <!-- / dropdown-menu -->
          </div>
      </div>
  </div>

  <button (click)="esportaJsonClick()">Esporta JSON</button>
</div>
<pre>
  {{listaTemplateAllenamento | json}}
</pre>
