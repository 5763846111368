
<div class="main-container centered">
  <content>
      <div class="welcome">
          <div class="title">All set!</div>
          <div class="subtitle">Grazie per aver risposto a tutte le domande</div>
      </div>
      <div class="login-register">
          <a href="/user/" class="button-default-no-icon">cominciamo</a>
      </div>
  </content>
</div>
