import { Component, OnInit } from '@angular/core';
import { AllenamentoService } from '../../_services/allenamento.service';

function formattedDate(d) {
  const yyyy = d.getFullYear();
  let mm = d.getMonth() + 1; // Months start at 0!
  let dd = d.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  var formatted = yyyy + '-' + mm + '-' + dd;
  return formatted;
}

class Esercizio {
  idesercizio:string;
  tempo:number;
  ripetizioni:number = 10;
  carico:number;
  progressione:string = "progressione";
  progressioneV1:number;
  progressioneV2:number;
}
class Circuito {
  idcircuito:string;
  ripetizioni:number = 5;
  riposo:number;
  esercizi:Esercizio[] = [new Esercizio()];
}
class GiornoOn {
  titolo: string;
  descrizione:string;
  linkVideo:string = "default-video-warmap.link";
  linkImmagine:string = "/assets/img/thumbnail.jpg";
  circuiti:Circuito[] = [new Circuito()];
}
class Settimana {
  giorniOn:GiornoOn[] = [new GiornoOn()];
}

class PianoAllenamento {
  dataInizio:string;
  dataFine:string;
  settimane:Settimana[] = [new Settimana()];

  constructor(){
    var oggi = new Date();
    this.dataInizio = formattedDate(oggi);
    this.dataFine = formattedDate(new Date(oggi.getTime() + 5*7*24*60*60*1000));
  }
}

@Component({
  selector: 'app-form-piano-allenamento',
  templateUrl: './form-piano-allenamento.component.html',
  styleUrls: ['./form-piano-allenamento.component.css']
})
export class FormPianoAllenamentoComponent implements OnInit {

  listaTemplateAllenamento;
  pianoAllenamento;
  listaCircuiti;
  listaEsercizi;

  tipiProgressione = ["progressione","lineare"];

  constructor(
    private allenamentoService: AllenamentoService
  ) {}

  ngOnInit(): void {
    this.initPianoAllenamento();
  }

  weeksBetween(d1s:string, d2s:string) {
    if(d1s==d2s || !d1s || !d2s) return 0;
    var t1 = new Date(d1s).getTime();
    var t2 = new Date(d2s).getTime();
    return Math.trunc((t2 - t1) / (7 * 24 * 60 * 60 * 1000));
  }

  initPianoAllenamento() {
    this.pianoAllenamento = this.allenamentoService.getPianiAllenamento()[0];
    console.log(this.pianoAllenamento);
    this.listaCircuiti = this.allenamentoService.getListaCircuiti();
    this.listaEsercizi = this.allenamentoService.getListaEsercizi();
    this.listaTemplateAllenamento = this.allenamentoService.getListaTemplateAllenamento();
  }

  //Bottoni Settimana
  nuovaSettimanaClick() {
    this.pianoAllenamento.settimane.push(new Settimana());
  }
  duplicaSettimanaClick(settimana) {
    var sCopy = JSON.parse(JSON.stringify(settimana));
    this.pianoAllenamento.settimane.push(sCopy);
  }
  eliminaSettimanaClick(settimanaIndex) {
    this.pianoAllenamento.settimane.splice(settimanaIndex,1);
  }

  //Bottoni Giorni
  nuovoGiornoClick(iSettimana) {
    this.pianoAllenamento.settimane[iSettimana].giorniOn.push(new GiornoOn());
  }
  duplicaGiornoClick(iSettimana,giorno) {
    var gCopy = JSON.parse(JSON.stringify(giorno));
    this.pianoAllenamento.settimane[iSettimana].giorniOn.push(gCopy);
  }
  eliminaGiornoClick(iSettimana,giornoIndex) {
    this.pianoAllenamento.settimane[iSettimana].giorniOn.splice(giornoIndex,1);
  }

  //Bottoni Circuito
  nuovoCircuitoClick(iSettimana,iGiorno) {
    this.pianoAllenamento.settimane[iSettimana].giorniOn[iGiorno].circuiti.push(new Circuito());
  }
  duplicaCircuitoClick(iSettimana,iGiorno,circuito) {
    var cCopy = JSON.parse(JSON.stringify(circuito));
    this.pianoAllenamento.settimane[iSettimana].giorniOn[iGiorno].circuiti.push(cCopy);
  }
  eliminaCircuitoClick(iSettimana,iGiorno,circuitoIndex) {
    this.pianoAllenamento.settimane[iSettimana].giorniOn[iGiorno].circuiti.splice(circuitoIndex,1);
  }

  //Bottoni Esercizi
  nuovoEsercizioClick(iSettimana,iGiorno,iCircuito) {
    this.pianoAllenamento.settimane[iSettimana].giorniOn[iGiorno].circuiti[iCircuito].esercizi.push(new Esercizio());
  }

  onRipristinaPianoAllenamentoClick() {
    this.pianoAllenamento = new PianoAllenamento();
  }

  esportaJsonClick() {
    var originalData = this.pianoAllenamento;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([JSON.stringify(originalData, null, 2)], {
      type: "application/json"
    }));
    var nomeFile = window.prompt("Nome del file json","nome-cognome");
    a.setAttribute("download", nomeFile+".json");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
