import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UserModule } from './@user/user.module'

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { DataTablesModule } from "angular-datatables";

import { AuthService } from './_services/auth.service';
import { AllenamentoService } from './_services/allenamento.service';
import { NutrizioneService } from './_services/nutrizione.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegistratiComponent } from './registrati/registrati.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SplashPageComponent } from './splash-page/splash-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingStep1Component } from './onboarding/onboarding-step1/onboarding-step1.component';
import { OnboardingStep2Component } from './onboarding/onboarding-step2/onboarding-step2.component';
import { OnboardingStep3Component } from './onboarding/onboarding-step3/onboarding-step3.component';
import { OnboardingStep4Component } from './onboarding/onboarding-step4/onboarding-step4.component';
import { OnboardingStep5Component } from './onboarding/onboarding-step5/onboarding-step5.component';
import { OnboardingEndComponent } from './onboarding/onboarding-end/onboarding-end.component';

import { FormPianoAlimentareComponent } from './_components/form-piano-alimentare/form-piano-alimentare.component';
import { FormPianoAllenamentoComponent } from './_components/form-piano-allenamento/form-piano-allenamento.component';
//import { VjsPlayerComponent } from './_components/vjs-player/vjs-player.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastContainerModule  } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistratiComponent,
    ForgotPasswordComponent,
    SplashPageComponent,
    PageNotFoundComponent,
    OnboardingStep1Component,
    OnboardingComponent,
    OnboardingStep2Component,
    OnboardingStep3Component,
    OnboardingStep4Component,
    OnboardingStep5Component,
    OnboardingEndComponent,
    FormPianoAllenamentoComponent
    //VjsPlayerComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    DataTablesModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-right' }),
  ],
  providers: [
    AuthService,
    AllenamentoService,
    NutrizioneService,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  
})
export class AppModule { }
