import { Injectable } from '@angular/core';

import { NutrizioneService } from './nutrizione.service';

import pianoAllenamentoJson from '../../assets/json/coach-allenamento.json';
import eserciziJson from '../../assets/json/esercizi.json';
import circuitiJson from '../../assets/json/circuiti.json';
import listaTemplateAllenamentoJson from '../../assets/json/francescoTemplateAllenamento.json';

@Injectable({
  providedIn: 'root'
})
export class AllenamentoService {

  pianiAllenamento;
  listaCircuiti;
  listaEsercizi;

  listaTemplateAllenamento;

  constructor(
    private nutrizioneService: NutrizioneService
  ) {
    this.pianiAllenamento = [pianoAllenamentoJson];
    this.listaCircuiti = circuitiJson;
    this.listaEsercizi = eserciziJson;
    //listaTemplateAllenamentoJson si inizializza con la get
  }

  getPianiAllenamento() {
    return this.pianiAllenamento;
  }

  salvaPianiAllenamento(pianiAllenamento) {
    this.pianiAllenamento = pianiAllenamento;
  }

  getListaCircuiti() {
    return this.listaCircuiti;
  }
  getCircuitoById(idcircuito) {
    for (var i = 0; i < this.listaCircuiti.length; i++) {
      if (parseInt(idcircuito) == parseInt(this.listaCircuiti[i].idcircuito)) {
        return this.listaCircuiti[i];
      }
    }
  }
  getListaEsercizi() {
    return this.listaEsercizi;
  }

  getListaTemplateAllenamento() {
    if (this.listaTemplateAllenamento)
      return this.listaTemplateAllenamento;

    var t = {};

    for (var i = 0; i < listaTemplateAllenamentoJson.length; i++) {
      //t[listaTemplateAllenamentoJson[i].idtemplate] = ..
    }
  }

  getAllenamentoDelGiorno(giornoX) {
    var pianoAllenamento = this.pianiAllenamento[0];
    var pianoAlimentare = this.nutrizioneService.getPianoAlimentare();

    console.log(pianoAllenamento);

    var giornoXObj = new Date(giornoX);

    var dataInizioObj = new Date(pianoAllenamento.dataInizio);
    var dataInizioDay = (dataInizioObj.getDay() + 6) % 7;
    var giornoSettimanaInizioObj = new Date(dataInizioObj);
    giornoSettimanaInizioObj.setDate(
      giornoSettimanaInizioObj.getDate() - dataInizioDay
    );

    //lgiornoSettimanaFineObj sarà il primo lunedi al difuori delle settimane di allenamento
    var giornoSettimanaFineObj = new Date(giornoSettimanaInizioObj);
    giornoSettimanaFineObj.setDate(
      giornoSettimanaFineObj.getDate() + (pianoAllenamento.settimane.length + 1) * 7
    );

    var nSettimana = 0;
    while (
      nSettimana < pianoAllenamento.settimane.length
      && (giornoSettimanaInizioObj.getTime() > giornoXObj.getTime()
        || giornoSettimanaFineObj.getTime() < giornoXObj.getTime()
      )
    ) {
      nSettimana++;
    }

    if (nSettimana >= pianoAllenamento.settimane.length) {
      return false;
    }

    var giornoXgiornoDellaSettimana = (giornoXObj.getDay() + 6) % 7;
    var nGiornoOn = pianoAlimentare.giornoOn.giorniSettimana.indexOf(giornoXgiornoDellaSettimana);

    var out = {
      countSettimane: pianoAllenamento.settimane.length,
      nSettimana: nSettimana,
      nGiornoOn: false,
      giornoOn: false,
      tipoGiorno: "giornoOff"
    };

    if (pianoAlimentare.giornoOff.giorniSettimana.indexOf(giornoXgiornoDellaSettimana) != -1) {
      out.tipoGiorno = "giornoSgarro";
    }

    if (
      nGiornoOn != -1
      && pianoAllenamento.settimane[nSettimana].giorniOn[nGiornoOn]
    ) {
      out.nGiornoOn = nGiornoOn;
      out.giornoOn = pianoAllenamento.settimane[nSettimana].giorniOn[nGiornoOn];
      out.tipoGiorno = "giornoOn";
      console.log(out);
    }
    return out;
  }
}
