<div class="main-container centered">
  <content>
    <!-- step indicator breadcrumb -->
    <div class="step-indicator breadcrumb">
        <div class="title">misurazioni</div>
        <div class="step-area">
            <div class="step 0 active"></div>
            <div class="step 1 active"></div>
            <div class="step 2"></div>
            <div class="step 3"></div>
            <div class="step 4"></div>
        </div>
        <div class="description">Step 2 di 5</div>
    </div>
    <!-- / step indicator breadcrumb -->
    <iframe width="530" height="315" src="https://www.youtube.com/embed/YRn4pun8lK8?si=Meg1-UX3SkkzipgK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    <form name="onboarding" id="onboarding" method="post" enctype="multipart/form-data">
      <div class="user-measures">
          <img class="image petto" src="/assets/img/onboarding/petto.jpg" alt="">
          <!-- text imput without helper text-->
          <div class="text-input petto">
              <div class="title">petto</div>
              <div class="input">
                <input type="text" name="inputPetto" id="inputPetto" placeholder="cm" required>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
          <img class="image addome" src="/assets/img/onboarding/addome.jpg" alt="">
          <!-- text imput without helper text-->
          <div class="text-input addome">
              <div class="title">addome</div>
              <div class="input">
                  <input type="number" name="inputAddome" id="inputAddome" placeholder="cm" required>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
          <img class="image girovita" src="/assets/img/onboarding/girovita.png" alt="">
          <!-- text imput without helper text-->
          <div class="text-input girovita">
              <div class="title">girovita</div>
              <div class="input">
                  <input type="text" name="inputGirovita" id="inputGirovita" placeholder="cm" required>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
          <img class="image glutei" src="/assets/img/onboarding/glutei.png" alt="">
          <!-- text imput without helper text-->
          <div class="text-input glutei">
              <div class="title">glutei</div>
              <div class="input">
                  <input type="text" name="inputGlutei" id="inputGlutei" placeholder="cm" required>
              </div>
              <div class="helper-text"></div>
          </div>
          <!-- / text imput without helper text-->
          <img class="image braccio" src="/assets/img/onboarding/bracciosx.png" alt="">
          <div class="input-group">
              <!-- text imput without helper text-->
              <div class="text-input bracciosx">
                  <div class="title">braccio sx</div>
                  <div class="input">
                      <input type="text" name="inputBracciosx" id="inputBracciosx" placeholder="cm" required>
                  </div>
                  <div class="helper-text"></div>
              </div>
              <!-- / text imput without helper text-->
              <!-- text imput without helper text-->
              <div class="text-input bracciodx">
                  <div class="title">braccio dx</div>
                  <div class="input">
                      <input type="text" name="inputBracciodx" id="inputBracciodx" placeholder="cm" required>
                  </div>
                  <div class="helper-text"></div>
              </div>
              <!-- / text imput without helper text-->
          </div>
          <img class="image coscia" src="/assets/img/onboarding/cosciadx.png" alt="">
          <div class="input-group">
              <!-- text imput without helper text-->
              <div class="text-input cosciasx">
                  <div class="title">coscia sx</div>
                  <div class="input">
                      <input type="text" name="inputcosciasx" id="inputcosciasx" placeholder="cm" required>
                  </div>
                  <div class="helper-text"></div>
              </div>
              <!-- / text imput without helper text-->
              <!-- text imput without helper text-->
              <div class="text-input cosciadx">
                  <div class="title">coscia dx</div>
                  <div class="input">
                      <input type="text" name="inputcosciadx" id="inputcosciadx" placeholder="cm" required>
                  </div>
                  <div class="helper-text"></div>
              </div>
              <!-- / text imput without helper text-->
          </div>
      </div>
      <div class="user-photo">
          <div class="title">carica le tue foto</div>
          <div class="description">Posizionati in un luogo ben illuminato, assicurati di indossare biancheria intima o costume e di mostrare tutte le parti del corpo.</div>
          <!--<img class="image photo-upload" src="/assets/img/img-placeholder.jpg" alt="">-->
          <!--upload form -->

          <div id="uploadform"  class="upload-form foto-frontale">
            <div class="title">foto frontale</div>
            <label id="labelInput" class="upload-area">
                <input id="fileElem" type="file" name="fronte" onchange="updateUploadDisplay(this)" required>
                <img src="/assets/img/icons/arrow-bar-up.svg" alt="upload-icon">
                Upload
            </label>
            <div class="success" style="display: none;">
                <img src="/assets/img/icons/check-black.svg" alt="upload-icon">
                <span class="filename">Nome foto.jpeg</span>
            </div>
          </div>

          <!-- / upload form-->
          <!--upload form -->
          <div id="uploadform1" class="upload-form foto-laterale">
              <div class="title">foto laterale</div>
              <label id="labelInput1" class="upload-area">
                  <input type="file" name="lato" id="fileElem1" onchange="updateUploadDisplay(this)" required>
                  <img src="/assets/img/icons/arrow-bar-up.svg" alt="upload-icon">
                  Upload
              </label>
              <div class="success" style="display: none;">
                  <img src="/assets/img/icons/check-black.svg" alt="upload-icon">
                  <span class="filename">Nome foto.jpeg</span>
              </div>
          </div>
          <!-- / upload form-->
          <!--upload form -->
          <div id="uploadform2" class="upload-form foto-retro">
              <div class="title">foto retro</div>
              <label id="labelInput2" class="upload-area">
                  <input type="file" name="retro" id="fileElem2" onchange="updateUploadDisplay(this)" required>
                  <img src="/assets/img/icons/arrow-bar-up.svg" alt="upload-icon">
                  Upload
              </label>
              <div class="success" style="display: none;">
                  <img src="/assets/img/icons/check-black.svg" alt="upload-icon">
                  <span class="filename">Nome foto.jpeg</span>
              </div>
          </div>
          <!-- / upload form-->
      </div>
          <input type="hidden" name="step" value="step2" />
      <br>
      <button type="submit" id="submit" class="button-default-no-icon">continua</button>
      <!--<a href="/onboarding/step-3" id="submit" class="button-default-no-icon">continua</a>-->
    </form>
</content>
</div>
