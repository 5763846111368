import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtPayload, jwtDecode } from "jwt-decode";
import { LoginReponse, environment } from '../../environments/environment';
import { debuglog } from 'util';

@Injectable({
  providedIn: 'root'
})
export class NutrizioneDataService {

  private userSubject: BehaviorSubject<any | null>;
  public user: Observable<any | null>;
  @Input() _ApiReponse!: LoginReponse;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.userSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')!));
    this.user = this.userSubject.asObservable();
    this._ApiReponse = {};
  }

  public get userValue() {
    return this.userSubject.value;
  }

  getMealPlan(_date: string) {

    let _token = localStorage.getItem('userToken');
    let _idutente = localStorage.getItem('userUserid');
    let _data = _date;

    //var _obj = { token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE3MDgwNzgyNTYsImlzcyI6Imh0dHBzOi8vd3d3LmFwcGlsZ2l1c3RvLmNvbSIsImV4cCI6MTcxMzI1ODY1NiwiZGF0YSI6eyJ1c2VyaWQiOiIxIiwicnVvbG8iOiJ1c2VyIn19.ETdC-vsMXPBt3SDqDs8NSw3kfxAL9KSLX2IbAFP5ObTlJHJPFE9lZWozkU-MYopWUBq4ucZyRn1W6S-RmTkXkg', idutente: '1', data: '2024-03-06' };

    return this.http.post<any>(`${environment.apiUrl}/services/users/meals/getMealPlan.php`, { token: _token, idutente: _idutente, data: _data })
      .pipe(map(data => {
      
        return data
      }))
      ;
  }

  getMacroFoodList() {

    let _token = localStorage.getItem('userToken');
    let _idutente = localStorage.getItem('userUserid');

      //var _obj = { token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJpYXQiOjE3MDgwNzgyNTYsImlzcyI6Imh0dHBzOi8vd3d3LmFwcGlsZ2l1c3RvLmNvbSIsImV4cCI6MTcxMzI1ODY1NiwiZGF0YSI6eyJ1c2VyaWQiOiIxIiwicnVvbG8iOiJ1c2VyIn19.ETdC-vsMXPBt3SDqDs8NSw3kfxAL9KSLX2IbAFP5ObTlJHJPFE9lZWozkU-MYopWUBq4ucZyRn1W6S-RmTkXkg' };

    return this.http.post<any>(`${environment.apiUrl}/services/users/meals/getMacroFoodList.php`, { token: _token})
      .pipe(map(data => {
        return data
      }))
      ;
  }



}
