<div class="main-container centered">
  <content>
            <!-- step indicator breadcrumb -->
            <div class="step-indicator breadcrumb">
                <div class="title">benessere</div>
                <div class="step-area">
                    <div class="step 0 active"></div>
                    <div class="step 1 active"></div>
                    <div class="step 2 active"></div>
                    <div class="step 3 active"></div>
                    <div class="step 4"></div>
                </div>
                <div class="description">Step 4 di 5</div>
            </div>
            <!-- / step indicator breadcrumb -->
            <div class="sleep-quality progressbar">
                <!-- progress bar -->
                <div class="progress-bar">
                    <div class="title">Qualità del sonno</div>
                    <div class="progress-area">
                        <progress id="qualitasonnoprogress" max="100" value="33" onclick="updateProgressBar(this)"></progress>
                        <input id="qualitasonno" type="hidden" name="qualitasonno" value="" required/>
                    </div>
                    <div class="description">
                        <span class="left">Pessima</span>
                        <span class="right">Ottima</span>
                    </div>
                </div>
                <!-- / progress bar -->
            </div>
            <div class="questions stress-level radiobuttons">
                <div class="title">Livello di stress</div>
                <!-- radiobuttons 1 -->
                <label class="input-container" for="stress-level-1">
                    <input type="radio" id="stress-level-1" name="stress-level" value="stress-level-1" required>
                    Cronico, ingestibile
                </label>
                <!-- / radiobuttons 1 -->

                <!-- radiobuttons 2 -->
                <label class="input-container" for="stress-level-2">
                    <input type="radio" id="stress-level-2" name="stress-level" value="stress-level-2" required>
                    Ricorrente, a volte ingestibile
                </label>
                <!-- / radiobuttons 2 -->

                <!-- radiobuttons 3 -->
                <label class="input-container" for="stress-level-3">
                    <input type="radio" id="stress-level-3" name="stress-level" value="stress-level-3" required>
                    Occasionale, gestibile
                </label>
                <!-- / radiobuttons 3 -->

                <!-- radiobuttons 4 -->
                <label class="input-container" for="stress-level-4">
                    <input type="radio" id="stress-level-4" name="stress-level" value="stress-level-4" required>
                    Nessuno
                </label>
                <!-- / radiobuttons 4 -->

                <!-- radiobuttons 5 -->
                <label class="input-container" for="stress-level-5">
                    <input type="radio" id="stress-level-5" name="stress-level" value="stress-level-5" required>
                    Altro
                </label>
                <!-- / radiobuttons 5 -->
            </div>
            <div class="questions smoker radiobuttons">
                <div class="title">Sei un fumatore?</div>
                <!-- radiobuttons 1 -->
                <label class="input-container" for="smoker-level-1">
                    <input type="radio" id="smoker-level-1" name="smoker-level" value="smoker-level-1" required>
                    Regolare
                </label>
                <!-- / radiobuttons 1 -->

                <!-- radiobuttons 2 -->
                <label class="input-container" for="smoker-level-2">
                    <input type="radio" id="smoker-level-2" name="smoker-level" value="smoker-level-2" required>
                    Occasionale
                </label>
                <!-- / radiobuttons 2 -->

                <!-- radiobuttons 3 -->
                <label class="input-container" for="smoker-level-3">
                    <input type="radio" id="smoker-level-3" name="smoker-level" value="smoker-level-3" required>
                    Non fumo
                </label>
                <!-- / radiobuttons 3 -->

            </div>
            <div class="questions coffee radiobuttons">
                <div class="title">Bevi caffè?</div>
                <!-- radiobuttons 1 -->
                <label class="input-container" for="coffee-level-1">
                    <input type="radio" id="coffee-level-1" name="coffee-level" value="coffee-level-1" required>
                    Non mi sveglio senza caffè
                </label>
                <!-- / radiobuttons 1 -->

                <!-- radiobuttons 2 -->
                <label class="input-container" for="coffee-level-2">
                    <input type="radio" id="coffee-level-2" name="coffee-level" value="coffee-level-2" required>
                    Senza caffè ho poca energia
                </label>
                <!-- / radiobuttons 2 -->

                <!-- radiobuttons 3 -->
                <label class="input-container" for="coffee-level-3">
                    <input type="radio" id="coffee-level-3" name="coffee-level" value="coffee-level-3" required>
                    Lo bevo quando sono stanco
                </label>
                <!-- / radiobuttons 3 -->

                <!-- radiobuttons 4 -->
                <label class="input-container" for="coffee-level-4">
                    <input type="radio" id="coffee-level-4" name="coffee-level" value="coffee-level-4" required>
                    Potrei farne a meno
                </label>
                <!-- / radiobuttons 4 -->

                <!-- radiobuttons 5 -->
                <label class="input-container" for="coffee-level-5">
                    <input type="radio" id="coffee-level-5" name="coffee-level" value="coffee-level-5" required>
                    Lo bevo raramente
                </label>
                <!-- / radiobuttons 5 -->

                <!-- radiobuttons 6 -->
                <label class="input-container" for="coffee-level-6">
                    <input type="radio" id="coffee-level-6" name="coffee-level" value="coffee-level-6" required>
                    Altro
                </label>
                <!-- / radiobuttons 6 -->

            </div>
            <div class="questions wakeup radiobuttons">
                <div class="title">Come ti senti appena sveglio?</div>
                <!-- radiobuttons 1 -->
                <label class="input-container" for="wakeup-level-1">
                    <input type="radio" id="wakeup-level-1" name="wakeup-level" value="wakeup-level-1" required>
                    Fiacco e stanco
                </label>
                <!-- / radiobuttons 1 -->

                <!-- radiobuttons 2 -->
                <label class="input-container" for="wakeup-level-2">
                    <input type="radio" id="wakeup-level-2" name="wakeup-level" value="wakeup-level-2" required>
                    Energico
                </label>
                <!-- / radiobuttons 2 -->
            </div>
            <div class="questions pathology text">
                <!-- text imput without helper text-->
                <div class="text-input">
                    <div class="title">Soffri di qualche patologia?</div>
                    <div class="input">
                        <input type="text" name="patologia" id="patologia" placeholder="diabete, tiroidismo, ..." required>
                    </div>
                    <div class="helper-text"></div>
                </div>
                <!-- / text imput without helper text-->
            </div>
            <br>
            <button type="submit" id="submit" class="button-default-no-icon">continua</button><!--
            <a href="/onboarding/step-5" class="button-default-no-icon">continua</a>-->
        </content>
</div>
