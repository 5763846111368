import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from "@angular/common";

import { LoginComponent } from './login/login.component';
import { RegistratiComponent } from './registrati/registrati.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SplashPageComponent } from './splash-page/splash-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingStep1Component } from './onboarding/onboarding-step1/onboarding-step1.component';
import { OnboardingStep2Component } from './onboarding/onboarding-step2/onboarding-step2.component';
import { OnboardingStep3Component } from './onboarding/onboarding-step3/onboarding-step3.component';
import { OnboardingStep4Component } from './onboarding/onboarding-step4/onboarding-step4.component';
import { OnboardingStep5Component } from './onboarding/onboarding-step5/onboarding-step5.component';
import { OnboardingEndComponent } from './onboarding/onboarding-end/onboarding-end.component';

//import { PianoAlimentareDataEntryComponent } from './@data-entry/piano-alimentare-data-entry/piano-alimentare-data-entry.component';
import { FormPianoAllenamentoComponent } from './_components/form-piano-allenamento/form-piano-allenamento.component';

const routes: Routes = [
  {path: '', component: SplashPageComponent},
  {path: 'login', component: LoginComponent},
  {path: 'registrati', component: RegistratiComponent},
  {path: 'forgot-password', component: ForgotPasswordComponent},

  {path: 'data-entry-piano-allenamento', component: FormPianoAllenamentoComponent},

  {path: 'onboarding/', component: OnboardingComponent},
  {path: 'onboarding/step-1', component: OnboardingStep1Component},
  {path: 'onboarding/step-2', component: OnboardingStep2Component},
  {path: 'onboarding/step-3', component: OnboardingStep3Component},
  {path: 'onboarding/step-4', component: OnboardingStep4Component},
  {path: 'onboarding/step-5', component: OnboardingStep5Component},
  {path: 'onboarding/end', component: OnboardingEndComponent},

  {path: 'user', loadChildren: () => import(`./@user/user.module`).then(m => m.UserModule)},
  {path: 'coach', loadChildren: () => import(`./@coach/coach.module`).then(m => m.CoachModule)},
  {path: 'admin', loadChildren: () => import(`./@admin/admin.module`).then(m => m.AdminModule)},
  //{path: 'data-entry', loadChildren: () => import(`./@data-entry/data-entry.module`).then(m => m.DataEntryModule)},

  {path: '**', component: PageNotFoundComponent}
  /*
  {path: 'dashboard', component: DashboardComponent},
  {path: 'dashboard/:_id_tema', component: DashboardComponent},
  {path: 'aggiorna-tema/:_id_tema', component: AggiornaTemaComponent},
  {path: 'nuovo-tema', component: AggiornaTemaComponent},
  {path: 'gruppi', component: GestioneGruppiComponent},
  {path: 'fisiognomica', component: FisiognomicaComponent},
  {path: '401', component: Error401Component},
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}
  */
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true }),CommonModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
