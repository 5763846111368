<div class="main-container centered">
  <content>
    <!-- step indicator breadcrumb -->
    <div class="step-indicator breadcrumb">
        <div class="title">Profilo personale</div>
        <div class="step-area">
            <div class="step 0 active"></div>
            <div class="step 1"></div>
            <div class="step 2"></div>
            <div class="step 3"></div>
            <div class="step 4"></div>
        </div>
        <div class="description">Step 1 di 5</div>
    </div>
    <!-- / step indicator breadcrumb -->
    <!-- text imput without helper text-->
    <form name="onboarding" id="onboarding" data-id="step1" method="post" enctype="multipart/form-data">
    <div class="text-input nome">
        <div class="title">Nome</div>
        <div class="input">
            <input type="text" name="nome" id="nome" placeholder="Alessandro" required>
        </div>
        <div class="helper-text"></div>
    </div>
         <div class="text-input cognome">
        <div class="title">Cognome</div>
        <div class="input">
            <input type="text" name="cognome" id="cognome" placeholder="Ferrari" required>
        </div>
        <div class="helper-text"></div>
    </div>
    <!-- / text imput without helper text-->
    <!-- text imput without helper text-->
    <div class="text-input altezza">
        <div class="title">Altezza</div>
        <div class="input">
            <input type="text" name="altezza" id="userHeight" placeholder="cm" required>
        </div>
        <div class="helper-text"></div>
    </div>
    <!-- / text imput without helper text-->
    <!-- text imput without helper text-->
    <div class="text-input peso">
        <div class="title">Peso</div>
        <div class="input">
            <input type="text" name="peso" id="peso" placeholder="kg" required>
        </div>
        <div class="helper-text">Inserisci il Tuo Peso</div>
    </div>
    <!-- / text imput without helper text-->
    <!-- input button -->
    <label class="form-input-button sesso">Sesso Biologico</label>

    <label class="input-container" for="firstRadio">
      <input type="radio"  name="sex" value="Uomo" checked required>
      Uomo
    </label>
    <label class="input-container" for="firstRadio">
      <input type="radio"  name="sex" value="Donna" required>
      Donna
    </label>

    <div class="description">Inserisci il Tuo Sesso</div>

    <!-- / input button-->
    <!-- text imput without helper text-->
    <div class="text-input eta">
        <div class="title">Età</div>
        <div class="input">
            <input type="text" name="eta" id="eta" placeholder="inserisci eta" required>
        </div>
        <div class="helper-text"></div>
    </div>
    <!-- / text imput without helper text-->
    <!-- text imput with helper text-->
    <div class="text-input professione">
        <div class="title">Professione</div>
        <div class="input">
            <input type="text" name="professione" id="userProfessione" placeholder="Inserisci Professione" required>
        </div>
        <div class="helper-text">
            In base al lavoro che svogli capiremo quanta energia spendi durante la tua giornata.
        </div>
    </div>

    <!-- / text imput with helper text-->
    <input type="hidden" name="step" value="step1" />
    <button type="submit" id="submit" class="button-default-no-icon">continua</button>
    <!--<a href="onboarding/step-2" id="submit" class="button-default-no-icon">continua</a>-->
   </form>
</content>
</div>
