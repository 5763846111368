import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service'
import { ApiReponse } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService

  ) { }

  howManyErrors;
  form;
  email;
  password;

  ngOnInit(): void {
    // ====================================================================
    // V V V V V FORM VALIDATION V V V V V
    // ====================================================================
    //
    // ASSUMES ONE FORM PER PAGE
    //
    // This validation script is as simple as it can get. Fields to validate
    // are manually defined ousite of HTML native API, in this very file.
    //
    // Styling is done under the form-validation section of components.css
    //
    // Requires putting a <div class="error"></div> just under the input filed.
    //
    // Requires adding .input-control class to the input wrapper.

    // Assumes only one form in the page
    this.form = document.querySelector('form');

    // Selecting all fields to check
    // (Dont forget to treat them in validateInputs)
    this.email = document.getElementById('userEmail') as HTMLInputElement;
    this.password = document.getElementById('userPassword') as HTMLInputElement;

    // Storing number of errors
    this.howManyErrors = 0;
  }

  // Function that toggles show/hide password transforming
  // input type="password" to type="text" and viceversa
  showPassword(fieldID) {
    // For calling it like:
    // onclick="passwordValidation('userPassword')"
    // Styled with .show-password
    let thisField = document.getElementById(fieldID) as HTMLInputElement;
    if (thisField.type === "password") {
      thisField.type = "text";
    } else {
      thisField.type = "password";
    }
  }


  // Setting error message, styling and adding +1 to howManueErrors
  setError(element, message) {
    const inputControl = element.parentElement;
    const errorDisplay = inputControl.querySelector('.error');

    errorDisplay.innerText = message;
    inputControl.classList.add('error');
    inputControl.classList.remove('success')

    this.howManyErrors++;
  }

  // Setting success message, styling
  setSuccess(element) {
    const inputControl = element.parentElement;
    const errorDisplay = inputControl.querySelector('.error');

    errorDisplay.innerText = '';
    inputControl.classList.add('success');
    inputControl.classList.remove('error');
  };

  // Validating email
  isValidEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Block of code that validates all the fields we've got in line +34
  validateInputs() {
    const emailValue = this.email.value.trim();
    const passwordValue = this.password.value.trim();

    if (emailValue === '') {
      this.setError(this.email, 'Inserire un indirizzo e-mail valido');
    } else if (!this.isValidEmail(emailValue)) {
      this.setError(this.email, 'Inserire un indirizzo e-mail valido');
    } else {
      this.setSuccess(this.email);
    }

    if (passwordValue === '') {
      this.setError(this.password, 'È necessaria una password');
    } else if (passwordValue.length < 8) {
      this.setError(this.password, 'La password deve essere di almeno 8 caratteri')
    } else {
      this.setSuccess(this.password);
    }

  };

  onLoginSubmit(e) {
    e.preventDefault();
    console.log('ok');

    this.howManyErrors = 0;
    this.validateInputs();
    if (this.howManyErrors !== 0) {
      return;
    }

    const emailValue = this.email.value.trim();
    const passwordValue = this.password.value.trim();

    //console.log(emailValue,passwordValue);
    //if(emailValue == 'testuser@appilgiusto.com')
    //  this.router.navigateByUrl('/user');
    //else if(emailValue == 'testcoach@appilgiusto.com')
    //  this.router.navigateByUrl('/coach');
    //else if(emailValue == 'testadmin@appilgiusto.com')
    //  this.router.navigateByUrl('/admin');
    //this.router.navigateByUrl('/onboarding/');

    
   
    this.spinner.show();
    this.authService.login(emailValue, passwordValue)
      .subscribe({
        next: (result) => {
          this.spinner.hide();
          let response = result.response;
          if (response) {
            if (result.ruolo == "user")
              this.router.navigateByUrl('/user');
            if (result.ruolo == "coach")
              this.router.navigateByUrl('/coach');
          }
          else {
            this.toastr.error('', result.message);
          }
        },
        error: error => {
          this.toastr.error('', error);
          console.log(error);
        }
      });
  }

}
