<div class="main-container centered">
  <content>
    <div class="welcome">
      <div><img src="./assets/img/logo-splash.svg" alt=""></div>
    </div>
    <div class="login-register">
      <a routerLink="registrati" class="button-default-no-icon">REGISTRATI</a>
      <a routerLink="login" class="button-default-no-icon outline">LOGIN</a>
      <div class="disclaimer">
        Creando un nuovo account, accetti i nostri Terms of Service & Privacy Policy.
      </div>
      <!--
      <div class="accessi" style="text-align:center">
        <a href="admin/" class="login-link">Accesso Admin</a> - <a href="coach/" class="login-link">Accesso Coach</a>
      </div>
      -->
    </div>
  </content>
</div>
