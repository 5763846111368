<div class="main-container centered">
  <content>
            <!-- step indicator breadcrumb -->
            <div class="step-indicator breadcrumb">
                <div class="title">allenamento</div>
                <div class="step-area">
                    <div class="step 0 active"></div>
                    <div class="step 1 active"></div>
                    <div class="step 2 active"></div>
                    <div class="step 3 active"></div>
                    <div class="step 4 active"></div>
                </div>
                <div class="description">Step 5 di 5</div>
            </div>
            <!-- / step indicator breadcrumb -->
            <form  method="post" name="onboarding" id="onboarding" enctype="multipart/form-data">
            <div class="questions">
                <!-- text imput without helper text-->

                <div class="text-input training-time">
                    <div class="title">Da quanto tempo ti alleni?</div>
                    <div class="input">
                        <input type="text" name="training-time" id="training-time" placeholder="Mesi, anni, ..." required>
                    </div>
                    <div class="helper-text"></div>
                </div>
                <!-- / text imput without helper text-->
                <!-- text imput without helper text-->
                <div class="text-input training-type">
                    <div class="title">Che tipo di allenamento hai seguito?</div>
                    <div class="input">
                        <input type="text" name="training-type" id="training-type" placeholder="corpo libero, sollevamento pesi, ..." required>
                    </div>
                    <div class="helper-text injuries"></div>
                </div>
                <!-- / text imput without helper text-->
                <!-- text imput without helper text-->
                <div class="text-input">
                    <div class="title">Hai avuto infortuni in passato?</div>
                    <div class="input">
                        <input type="text" name="injuries" id="injuries" placeholder="rottura legamento ginocchio, ..." required>
                    </div>
                    <div class="helper-text"></div>
                </div>
                <!-- / text imput without helper text-->
            </div>
            <div class="questions training-time-per-week">
                <!-- progress bar -->
                <div class="progress-bar">
                    <div class="title">Quante volte ti alleni alla settimana?</div>
                    <div class="progress-area">
                        <progress max="100" value="33" onclick="updateProgressBar(this)"></progress>
                    </div>
                    <div class="description">
                        <span class="left">0</span>
                        <span class="right">+5</span>
                    </div>
                </div>
                <!-- / progress bar -->
            </div>
            <div class="questions bodyweight checkboxes">
                <div class="title">Performance attuali a Corpo Libero</div>
                    <!-- Square Checkbox 1 -->
                    <label class="input-container" for="bodyweight-square-checkbox-1">
                        <input name="corpolibero" type="checkbox" class="bodyweight square-checkbox" id="bodyweight-square-checkbox-1" value="Piegamenti sulle braccia (pushups)" required>
                        Piegamenti sulle braccia (pushups)
                    </label>
                    <!-- / Square Checkbox 1 -->

                    <!--indicator -->
                    <div class="form-indicator">
                      <div class="title">Numero ripetizioni</div>
                      <div class="controls">
                          <div class="minus" onclick="updateIndicatorNumber(this)"><img src="./assets/img/components/minus-with-container.svg" alt="minus"></div>
                          <div class="number">10</div>
                          <input type="hidden" name="numeroRipetizioni" id="numeroRipetizioni" value="">
                          <div class="plus" onclick="updateIndicatorNumber(this)"><img src="./assets/img/components/plus-with-container.svg" alt="plus"></div>
                      </div>
                    </div>
                    <!-- / indicator-->

                    <!-- Square Checkbox 2 -->
                    <label class="input-container" for="bodyweight-square-checkbox-2">
                        <input name="corpolibero" type="checkbox" class="bodyweight square-checkbox" id="bodyweight-square-checkbox-2" value="Dips alle parallele" required>
                        Dips alle parallele
                    </label>
                    <!-- / Square Checkbox 2 -->

                    <!-- Square Checkbox 3 -->
                    <label class="input-container" for="bodyweight-square-checkbox-3">
                        <input name="corpolibero" type="checkbox" class="bodyweight square-checkbox" id="bodyweight-square-checkbox-3" value="Piegamenti in verticale (HSPU)" required>
                        Piegamenti in verticale (HSPU)
                    </label>
                    <!-- / Square Checkbox 3 -->

                    <!-- Square Checkbox 4 -->
                    <label class="input-container" for="bodyweight-square-checkbox-4">
                        <input name="corpolibero" type="checkbox" class="bodyweight square-checkbox" id="bodyweight-square-checkbox-4" value="Trazioni alla sbarra (pullups)" required>
                        Trazioni alla sbarra (pullups)
                    </label>
                    <!-- / Square Checkbox 4 -->

                    <!-- Square Checkbox 5 -->
                    <label class="input-container" for="bodyweight-square-checkbox-5">
                        <input name="corpolibero" type="checkbox" class="bodyweight square-checkbox" id="bodyweight-square-checkbox-5" value="Trazioni orizzontali (body row)" required>
                        Trazioni orizzontali (body row)
                    </label>
                    <!-- / Square Checkbox 5 -->

            </div>
            <div class="questions wheight checkboxes">
                <div class="title">Performance attuali con Pesi</div>
                <!-- Square Checkbox 1 -->
                <label class="input-container" for="wheight-square-checkbox-1">
                    <input name="pesi" type="checkbox" class="wheight square-checkbox" id="wheight-square-checkbox-1" value="Panca piana con manubri" required>
                    Panca piana con manubri
                </label>
                <!-- / Square Checkbox 1 -->
                <!--indicator -->
                <div class="form-indicator bench-repetitions">
                  <div class="title">Numero ripetizioni</div>
                  <div class="controls">
                      <div class="minus" onclick="updateIndicatorNumber(this)"><img src="./assets/img/components/minus-with-container.svg" alt="minus"></div>
                      <div class="number">10</div>
                      <input type="hidden" name="bench-repetitions" id="bench-repetitions" value="" required>
                      <div class="plus" onclick="updateIndicatorNumber(this)"><img src="./assets/img/components/plus-with-container.svg" alt="plus"></div>
                  </div>
                </div>
                <!-- / indicator-->
                <!--indicator -->
                <div class="form-indicator bench-weight">
                  <div class="title">Numero ripetizioni</div>
                  <div class="controls">
                      <div class="minus" onclick="updateIndicatorNumber(this)"><img src="./assets/img/components/minus-with-container.svg" alt="minus"></div>
                      <div class="number">10</div>
                      <input type="hidden" name="bench-weight" id="bench-weight" value="" required>
                      <div class="plus" onclick="updateIndicatorNumber(this)"><img src="./assets/img/components/plus-with-container.svg" alt="plus"></div>
                  </div>
                </div>
                <!-- / indicator-->
                <!-- Square Checkbox 2 -->
                <label class="input-container" for="wheight-square-checkbox-2">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-2" value="Dips alle parallele" required>
                    Dips alle parallele
                </label>
                <!-- / Square Checkbox 2 -->

                <!-- Square Checkbox 3 -->
                <label class="input-container" for="wheight-square-checkbox-3">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-3" value="Panca piana con bilancere" required>
                    Panca piana con bilancere
                </label>
                <!-- / Square Checkbox 3 -->

                <!-- Square Checkbox 4 -->
                <label class="input-container" for="wheight-square-checkbox-4">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-4" value="Dips zavorrati" required>
                    Dips zavorrati
                </label>
                <!-- / Square Checkbox 4 -->

                <!-- Square Checkbox 5 -->
                <label class="input-container" for="wheight-square-checkbox-5">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-5" value="Military press / lento avanti" required>
                    Military press / lento avanti
                </label>
                <!-- / Square Checkbox 5 -->

                <!-- Square Checkbox 6 -->
                <label class="input-container" for="wheight-square-checkbox-6">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-6" value="Lat machine" required>
                    Lat machine
                </label>
                <!-- / Square Checkbox 6 -->

                <!-- Square Checkbox 7 -->
                <label class="input-container" for="wheight-square-checkbox-7">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-7" value="Trazioni zavorrate" required>
                    Trazioni zavorrate
                </label>
                <!-- / Square Checkbox 7 -->

                <!-- Square Checkbox 8 -->
                <label class="input-container" for="wheight-square-checkbox-8">
                    <input type="checkbox" name="pesi" class="wheight square-checkbox" id="wheight-square-checkbox-8" value="Rematore" required>
                    Rematore
                </label>
                <!-- / Square Checkbox 8 -->

            </div>
            <div class="questions legs checkboxes">
                <div class="title">Performance attuali compartimento Gambe</div>
                    <!-- Square Checkbox 1 -->
                    <label class="input-container" for="legs-square-checkbox-1">
                        <input type="checkbox" name="legs" class="legs square-checkbox" id="legs-square-checkbox-1" value="Squat a corpo libero" required>
                        Squat a corpo libero
                    </label>
                    <!-- / Square Checkbox 1 -->

                    <!-- Square Checkbox 2 -->
                    <label class="input-container" for="legs-square-checkbox-2">
                        <input type="checkbox" name="legs" class="legs square-checkbox" id="legs-square-checkbox-2" value="Squat con bilanciere / sovraccarichi" required>
                        Squat con bilanciere / sovraccarichi
                    </label>
                    <!-- / Square Checkbox 2 -->

                    <!-- Square Checkbox 3 -->
                    <label class="input-container" for="legs-square-checkbox-3">
                        <input type="checkbox" name="legs" class="legs square-checkbox" id="legs-square-checkbox-3" value="Squat a corpo libero / sovraccarichi" required>
                        Squat a corpo libero / sovraccarichi
                    </label>
                    <!-- / Square Checkbox 3 -->

                    <!-- Square Checkbox 4 -->
                    <label class="input-container" for="legs-square-checkbox-4">
                        <input type="checkbox" name="legs" class="legs square-checkbox" id="legs-square-checkbox-4" value="Stacchi con bilanciere" required>
                        Stacchi con bilanciere
                    </label>
                    <!-- / Square Checkbox 4 -->

            </div>
            <br>
            <input type="hidden" name="step" value="end" />
            <button type="submit" id="submit" class="button-default-no-icon">continua</button><!--
            <a href="onboarding/end" id="submit" class="button-default-no-icon">continua</a>-->
            </form>

        </content>
</div>
