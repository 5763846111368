<div class="main-container centered">
  <content>
  <!-- step indicator breadcrumb -->
  <div class="step-indicator breadcrumb">
      <div class="title">misurazioni</div>
      <div class="step-area">
          <div class="step 0 active"></div>
          <div class="step 1 active"></div>
          <div class="step 2 active"></div>
          <div class="step 3"></div>
          <div class="step 4"></div>
      </div>
      <div class="description">Step 3 di 5</div>
  </div>
  <form name="onboarding" id="onboarding" method="post" enctype="multipart/form-data">
    <!-- / step indicator breadcrumb -->
    <div class="obiettivo radiobuttons">
        <div class="title">Obiettivo</div>
        <label class="input-container" for="obiettivo-dimagrire">
            <input type="radio" id="obiettivo-dimagrire" name="obiettivo" value="Dimagrire" required>
            Dimagrire/benessere</label>
        <label class="input-container" for="obiettivo-performance">
            <input type="radio" id="obiettivo-performance" name="obiettivo" value="Performance" required>
            Massa muscolare/Performance</label>
    </div>
    <div class="questions diet text">
        <!-- text imput without helper text-->
        <div class="text-input">
            <div class="title">hai già seguito alcune diete in passato?</div>
            <div class="input">
                <input type="text" name="dieta" id="pastDiet" placeholder="Digiuno intermittente, low carb, ..." required>
            </div>
            <div class="helper-text"></div>
        </div>
        <!-- / text imput without helper text-->
        <!-- text imput without helper text-->
        <div class="text-input">
            <div class="title">Cosa mangi a colazione</div>
            <div class="input">
                <input type="text" name="eatcolazione" id="userEatsOnBreakfast" placeholder="Cereali, latte, fette biscottate, ..." required>
            </div>
            <div class="helper-text"></div>
        </div>
        <!-- / text imput without helper text-->
        <!-- text imput without helper text-->
        <div class="text-input">
            <div class="title">Cosa mangi a pranzo</div>
            <div class="input">
                <input type="text" name="pranzo" id="userEatsOnLunch" placeholder="Carboidrati, verdure, frutta, ..." required>
            </div>
            <div class="helper-text"></div>
        </div>
        <!-- / text imput without helper text-->
        <!-- text imput without helper text-->
        <div class="text-input">
            <div class="title">Cosa mangi a cena</div>
            <div class="input">
                <input type="text" name="cena" id="userEatsOnDinner" placeholder="Proteine, verdura, dolci, ..." required>
            </div>
            <div class="helper-text"></div>
        </div>
        <!-- / text imput without helper text-->
        <!-- text imput without helper text-->
        <div class="text-input">
            <div class="title">Cosa mangi come spuntino</div>
            <div class="input">
                <input type="text" name="snack" id="userEatsOnSnack" placeholder="Frutta secca, frutta, ..." required>
            </div>
            <div class="helper-text"></div>
        </div>
        <!-- / text imput without helper text-->
    </div>
    <div class="questions breakfast radiobuttons">
        <div class="title">Quanto è importante per te la colazione?</div>
        <!-- radiobuttons -->
        <label class="input-container" for="breakfast-very-important-1">
            <input type="radio" id="breakfast-very-important-11" name="colazione" value="breakfast-very-important-1" required>
            Non posso farne a meno
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="breakfast-very-important-2">
            <input type="radio" id="breakfast-very-important-2" name="colazione" value="breakfast-very-important-2" required>
            Vorrei saltarla ma non riesco
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="breakfast-very-important-3">
            <input type="radio" id="breakfast-very-important-3" name="colazione" value="breakfast-very-important-3" required>
            Mi piace ma posso rinunciare
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="breakfast-very-important-4">
            <input type="radio" id="breakfast-very-important-4" name="colazione" value="breakfast-very-important-4" required>
            Non mi piace
        </label>
        <!-- / radiobuttons -->
    </div>
    <div class="questions hunger radiobuttons">
        <div class="title">Livello di fame</div>
        <!-- radiobuttons -->
        <label class="input-container" for="how-much-hunger-1">
            <input type="radio" id="how-much-hunger-1" name="fame" value="how-much-hunger-1" required>
            Cronica, non riesco a smettere
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="how-much-hunger-3">
            <input type="radio" id="how-much-hunger-2" name="fame" value="how-much-hunger-2" required>
            Molti giorni ho fame, altri per nulla
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="how-much-hunger-3">
            <input type="radio" id="how-much-hunger-3" name="fame" value="how-much-hunger-3" required>
            Ho fame solo prima dei pasti
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="how-much-hunger">
            <input type="radio" id="how-much-hunger-4" name="fame" value="how-much-hunger-4" required>
            Pochi giorni ho fame, altri per nulla
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="how-much-hunger">
            <input type="radio" id="how-much-hunger-5" name="fame" value="how-much-hunger-5" required>
            Non ho mai fame
        </label>
        <!-- / radiobuttons -->
    </div>
    <div class="meal-plan radiobuttons">
        <div class="title">Organizzazione dei pasti</div>
        <!-- radiobuttons -->
        <label class="input-container" for="meal-plan-1">
            <input type="radio" id="meal-plan-1" name="meal" value="meal-plan-1" required>
            Preparo tutti i pasti
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="meal-plan-2">
            <input type="radio" id="meal-plan-2" name="meal" value="meal-plan-2" required>
            Preparo solo pasti veloci e semplici
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="meal-plan-3">
            <input type="radio" id="meal-plan-3" name="meal" value="meal-plan-3" required>
            Preparo solo la cena
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="meal-plan-4">
            <input type="radio" id="meal-plan-4" name="meal" value="meal-plan-4" required>
            Qualcuno prepara i pasti per me
        </label>
        <!-- / radiobuttons -->
        <!-- radiobuttons -->
        <label class="input-container" for="meal-plan-5">
            <input type="radio" id="meal-plan-5" name="meal" value="meal-plan-5" required>
            Altro
        </label>
        <!-- / radiobuttons -->
    </div>
    <div class="do-not-eat cheboxes">
        <div class="title">cosa non mangi</div>
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-1">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-1" value="do-not-eat-1">
            Mangio tutto
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-2">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-2" value="do-not-eat-2">
            Latticini e derivati
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-3">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-3" value="do-not-eat-3">
            Carne
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-4">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-4" value="do-not-eat-4">
            Pesce e molluschi
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-5">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-5" value="do-not-eat-5">
            Legumi
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-6">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-6" value="do-not-eat-6">
            Cereali
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-7">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-7" value="do-not-eat-7">
            Frutta secca
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="do-not-eat-8">
            <input type="checkbox" name="noeat" class="square-checkbox" id="do-not-eat-8" value="do-not-eat-8">
            Altro
        </label>
        <!-- / Square Checkbox -->
    </div>
    <div class="supplements checkboxes">
        <div class="title">Fai uso di integratori?</div>
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-1">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-1" value="supplements-1">
            Nessuno
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-2">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-2" value="supplements-2">
            Proteine in polvere
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-3">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-3" value="supplements-3">
            Creatina
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-4">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-4" value="supplements-4">
            BCAA / EAA
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-5">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-5" value="supplements-5">
            Vitamine
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-6">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-6" value="supplements-6">
            Omega 3
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-7">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-7" value="supplements-7">
            ALA
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-8">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-8" value="supplements-8">
            Elettroliti
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-9">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-9" value="supplements-9">
            Glutammina
        </label>
        <!-- / Square Checkbox -->
        <!-- Square Checkbox  -->
        <label class="input-container" for="supplements-10">
            <input type="checkbox" name="suppint" class="square-checkbox" id="supplements-10" value="supplements-10">
            Altro
        </label>
        <!-- / Square Checkbox -->
    </div>
    <br>
    <button type="submit" id="submit" class="button-default-no-icon">continua</button>
    <!--
    <a href="/onboarding/step-4" class="button-default-no-icon">continua</a>-->
  </form>
</content>
</div>
