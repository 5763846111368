
<header>
  <div class="header-container">
    <img class="logo-icon" alt="" src="./assets/img/logo.svg">
  </div>
</header>
<main>
  <router-outlet></router-outlet>
  <ngx-spinner bdColor="rgba(7,1,1,0.95)" size="large" type="line-scale" color="#41B104" [fullScreen]="true">
    <p style="color: white">Loading...</p>
  </ngx-spinner>

  <!--<ngx-spinner></ngx-spinner>-->
</main>
